import { ContactsTwoTone, Edit, Email, LabelImportant, Phone } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { CSSProperties, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactType, ISupportCase, PermissionKeys, useHasPermission, Widget } from '../../../shared';
import { UpdateContactsModal } from '../update-contacts-modal/update-contacts-modal.component';

const contactIconStyles: CSSProperties = { marginRight: '4px', fontSize: 'inherit' };
const ContactIcon: FC<{ type: ContactType }> = ({ type }) => {
    switch (type) {
        case ContactType.Email:
            return <Email style={contactIconStyles} />;
        case ContactType.Phone:
            return <Phone style={contactIconStyles} />;
    }
};

export const Contacts: FC<{ supportCase: ISupportCase }> = ({ supportCase }) => {
    const { t } = useTranslation();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SUPPORT_CASES_WRITE);
    const [contactsModal, setContactsModal] = useState(false);

    return (
        <>
            <Widget
                icon={<ContactsTwoTone color="primary" />}
                title={t('contactInformation')}
                actions={
                    hasWritePermission && (
                        <IconButton onClick={() => setContactsModal(true)} data-testid="edit-contacts">
                            <Edit />
                        </IconButton>
                    )
                }
            >
                {supportCase.contacts.length === 0 && t('emptyStateContacts')}
                {supportCase.contacts.map((contact, index) => (
                    <Grid container direction="row" alignItems="center" key={contact.id}>
                        <LabelImportant
                            color="primary"
                            style={{
                                marginRight: '4px',
                                visibility: contact.primaryContact ? 'visible' : 'hidden',
                            }}
                        />
                        <ContactIcon type={contact.type} />
                        <div data-testid={`contact-${index}-data`}>{contact.data}</div>
                    </Grid>
                ))}
            </Widget>
            <UpdateContactsModal
                open={contactsModal}
                supportCase={supportCase.id}
                contacts={supportCase.contacts || []}
                handleClose={() => setContactsModal(false)}
            />
        </>
    );
};
